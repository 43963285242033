import React, {Fragment, Component} from 'react';
import ApiHandler from '../api/api-handler';
import * as ParseUtils from '../utils/parser-utils';
import {VideoModel} from "../interfaces/CommonTypes";
import {Pagination, Alert} from "@material-ui/lab";
import ReactPlayer from 'react-player'
import ComparatorMatchingAction from "./ComparatorMatchingAction";
import {Select} from "@material-ui/core";

export interface VideoComparatorProps {
    sourceVideoList: string[],
    targetVideoList: string[],
    metadataList: string[],
    sourceFilePath: string,
}

export interface VideoComparatorState {
    numberofSecondsToChangeBothVideos: number,
    sourceVideoIndex: number,
    sourcePresignedUrlAvailable: boolean,
    sourceVideoPlaying: boolean,
    numberofSecondsToChangeSource: number,
    sourceDropdownSelectedValue: number,
    targetVideoIndex: number,
    targetPresignedUrlAvailable: boolean,
    targetVideoPlaying: boolean,
    numberofSecondsToChangeTarget: number,
    targetDropdownSelectedValue: number,
    pageNumber: number,
    freeFormPaginationValue: any,
    freeFormPaginationValueFailed: boolean,
}

export class VideoComparator extends Component<VideoComparatorProps, VideoComparatorState> {
    private serviceAPI: ApiHandler;
    private sourceVideoModel: VideoModel[];
    private sourceVideoPlayer: any;
    private targetVideoModel: VideoModel[];
    private targetVideoPlayer: any;

    public constructor(props: VideoComparatorProps) {
        super(props);
        this.serviceAPI = new ApiHandler();
        this.sourceVideoPlayer = null;
        this.targetVideoPlayer = null;
        this.state = {
            sourceVideoIndex: 0,
            numberofSecondsToChangeBothVideos: 0,
            sourcePresignedUrlAvailable: false,
            sourceVideoPlaying: false,
            numberofSecondsToChangeSource: 0,
            sourceDropdownSelectedValue: 0,
            targetVideoIndex: 0,
            targetPresignedUrlAvailable: false,
            targetVideoPlaying: false,
            numberofSecondsToChangeTarget: 0,
            targetDropdownSelectedValue: 0,
            pageNumber: 1,
            freeFormPaginationValue: 1,
            freeFormPaginationValueFailed: false,
        };
        this.sourceVideoModel = ParseUtils.parseVideoList(this.props.sourceVideoList);
        this.targetVideoModel = ParseUtils.parseVideoList(this.props.targetVideoList);
        this.fetchAndLoadBothVideos(this.state.sourceVideoIndex, this.state.targetVideoIndex);
    }

    fetchAndLoadBothVideos = (sourceVideoIndex: number, targetVideoIndex: number) => {
        this.setState({sourcePresignedUrlAvailable: true});
        this.setState({targetPresignedUrlAvailable: true});
    }

    fetchPresignedUrl = async(bucketname: string, filepath: string): Promise<string> => {
        return await this.serviceAPI.getPresignedUrl(bucketname, filepath);
    }

    pauseBothVideos = (event: any) => {
        this.setState({sourceVideoPlaying: false, targetVideoPlaying: false});
    }

    playBothVideos = (event: any) => {
        this.setState({sourceVideoPlaying: true, targetVideoPlaying: true});
    }

    restartVideos = (event: any) => {
        this.sourceVideoPlayer.seekTo(0, 'seconds');
        this.targetVideoPlayer.seekTo(0, 'seconds');
        this.setState({sourceVideoPlaying: false, targetVideoPlaying: false});
    }

    pauseAtSpecifiedLocation = (event: any) => {
        this.setState({sourceVideoPlaying: false, targetVideoPlaying: false});
        this.sourceVideoPlayer.seekTo(this.state.numberofSecondsToChangeBothVideos, 'seconds');
        this.targetVideoPlayer.seekTo(this.state.numberofSecondsToChangeBothVideos, 'seconds');
    }

    pauseSourceVideo = (event: any) => {
        this.setState({sourceVideoPlaying: false});
        this.sourceVideoPlayer.seekTo(this.state.numberofSecondsToChangeSource, 'seconds');
    }

    pauseTargetVideo = (event: any) => {
        this.setState({targetVideoPlaying: false});
        this.targetVideoPlayer.seekTo(this.state.numberofSecondsToChangeTarget, 'seconds');
    }

    handleNumberofSecondsToChangeBothVideos = (event: any) => {
        this.setState({numberofSecondsToChangeBothVideos: (isNaN(event.target.value) || event.target.value === '') ? 0 : parseFloat(event.target.value)});
    }

    handleNumberofSecondsToChangeSource = (event: any) => {
        this.setState({numberofSecondsToChangeSource: (isNaN(event.target.value) || event.target.value === '') ? 0 : parseFloat(event.target.value)});
    }

    handleNumberofSecondsToChangeTarget = (event: any) => {
        this.setState({numberofSecondsToChangeTarget: (isNaN(event.target.value) || event.target.value === '') ? 0 : parseFloat(event.target.value)});
    }

    handlePagination = (page: number) => {
        const updatedIndex = page - 1;
        this.fetchAndLoadBothVideos(updatedIndex, updatedIndex);
        this.setState({
            sourceVideoIndex: updatedIndex,
            targetVideoIndex: updatedIndex,
            pageNumber: page,
            freeFormPaginationValue: page,
            sourceVideoPlaying: false,
            targetVideoPlaying: false
        });
    }

    handlePaginatedPagination = (event: object, page: number) => {
        this.handlePagination(page);
    }

    handleFreeFormPagination = (event:any) => {
        const inputMediaFileSize = this.sourceVideoModel.length;
        if (this.state.freeFormPaginationValue <= 0 || this.state.freeFormPaginationValue > inputMediaFileSize) {
            this.setState({freeFormPaginationValueFailed: true});
        } else {
            this.handlePagination(this.state.freeFormPaginationValue);
        }
    }

    handleFreeFormPaginationChanges = (event:any) => {
        if (isNaN(event.target.value) || event.target.value === '') {
            this.setState({freeFormPaginationValue: "", freeFormPaginationValueFailed: false});
        }
        else {
            this.setState({freeFormPaginationValue: parseFloat(event.target.value), freeFormPaginationValueFailed: false});
        }
    }

    handleSourceDropdownChange = (event:any) => {
        this.fetchAndLoadBothVideos(event.target.value, this.state.targetVideoIndex);
        this.setState({sourceVideoIndex: event.target.value});
    }

    handleTargetDropdownChange = (event:any) => {
        this.fetchAndLoadBothVideos(this.state.sourceVideoIndex, event.target.value);
        this.setState({targetVideoIndex: event.target.value});
    }

    render() {
        return (
            <Fragment>
                <div id="compare-video-display">
                    <div className="row align-items-start justify-content-start">
                        <div className="col-10">
                            <div className="row align-items-start justify-content-start">
                                <div className="col">
                                    <label htmlFor="select-source-video">Select a source video</label>
                                    <Select
                                        native
                                        onChange={this.handleSourceDropdownChange}
                                        inputProps={{
                                            name: 'source-video',
                                            id: 'select-source-video',
                                        }}
                                        fullWidth
                                    >
                                        <option key="none" value="none" selected>Select a source video ...</option>
                                        {this.props.sourceVideoList.map((item, index) => (
                                            <option key= {index} value={index}>{item}</option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="col">
                                    <label htmlFor="select-target-video">Select a target video</label>
                                    <Select
                                        native
                                        onChange={this.handleTargetDropdownChange}
                                        inputProps={{
                                            name: 'target-video',
                                            id: 'select-target-video',
                                        }}
                                        fullWidth
                                    >
                                        <option key="none" value="none" selected>Select a target video ...</option>
                                        {this.props.targetVideoList.map((item, index) => (
                                            <option key= {index} value={index}>{item}</option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div style={{height: '20px', display: 'block'}}></div>
                            </div>
                            <div className="row align-items-start justify-content-start">
                                <div className="col">
                                    <h4>Source Video</h4>
                                    <p className="text-success lead">{this.sourceVideoModel[this.state.sourceVideoIndex].sourceurl}</p>
                                </div>
                                <div className="col">
                                    <h4>Target Video</h4>
                                    <p className="text-success lead">{this.targetVideoModel[this.state.targetVideoIndex].sourceurl}</p>
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div style={{height: "20px", display: "block"}}></div>
                            </div>

                            <div style={{height: "300px", display: "block"}}>
                                <div className="row align-items-start justify-content-start">
                                    <div className="col">
                                        <ReactPlayer ref={(player) => this.sourceVideoPlayer = player}
                                                     url={this.sourceVideoModel[this.state.sourceVideoIndex].sourceurl}
                                                     width="512px"
                                                     height="288px"
                                                     pip={true}
                                                     controls={true}
                                                     muted={false}
                                                     playing={this.state.sourceVideoPlaying}
                                                     className="react-player"
                                                     volume={0.5}
                                        ></ReactPlayer>
                                    </div>
                                    <div className="col">
                                        <ReactPlayer ref={(player) => this.targetVideoPlayer = player}
                                                     url={this.targetVideoModel[this.state.targetVideoIndex].sourceurl}
                                                     width="512px"
                                                     height="288px"
                                                     pip={true}
                                                     controls={true}
                                                     muted={false}
                                                     playing={this.state.targetVideoPlaying}
                                                     className="react-player"
                                                     volume={0.5}
                                        ></ReactPlayer>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div style={{height: '20px', display: 'block'}}></div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-auto">
                                    <button type="button" className="btn btn-secondary btn-lg mr-3"
                                            onClick={this.pauseBothVideos}>Pause
                                    </button>
                                    <button type="button" className="btn btn-secondary btn-lg mr-3" onClick={this.playBothVideos}>Play
                                    </button>
                                    <button type="button" className="btn btn-secondary btn-lg mr-3"
                                            onClick={this.restartVideos}>Restart
                                    </button>
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div style={{height: '20px', display: 'block'}}></div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col">
                                    <input className="form-control-lg mr-3" type="text" value={this.state.numberofSecondsToChangeSource}
                                           placeholder="# secs to pause at" onChange={this.handleNumberofSecondsToChangeSource} size={6} />
                                    <button type="button" className="btn btn-secondary btn-lg mr-3"
                                            onClick={this.pauseSourceVideo}>Pause source at
                                    </button>
                                </div>
                                <div className="col">
                                    <input className="form-control-lg mr-3" type="text" value={this.state.numberofSecondsToChangeBothVideos}
                                           placeholder="# secs to pause at" onChange={this.handleNumberofSecondsToChangeBothVideos} size={6} />
                                    <button type="button" className="btn btn-secondary btn-lg mr-3"
                                            onClick={this.pauseAtSpecifiedLocation}>Pause both videos at
                                    </button>
                                </div>
                                <div className="col">
                                    <input className="form-control-lg mr-3" type="text" value={this.state.numberofSecondsToChangeTarget}
                                           placeholder="# secs to pause at" onChange={this.handleNumberofSecondsToChangeTarget} size={6} />
                                    <button type="button" className="btn btn-secondary btn-lg mr-3"
                                            onClick={this.pauseTargetVideo}>Pause target at
                                    </button>
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div style={{height: '20px', display: 'block'}}></div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-auto">
                                    <Pagination
                                        count={this.props.sourceVideoList.length}
                                        page={this.state.pageNumber}
                                        showFirstButton={true}
                                        showLastButton={true}
                                        size="large"
                                        color="primary"
                                        onChange={this.handlePaginatedPagination}
                                        variant="outlined"
                                        shape="rounded"
                                    />
                                </div>
                                <div className="col-2 my-auto">
                                    <input className="form-control-lg mr-3" type="text" value={this.state.freeFormPaginationValue}
                                           placeholder="" onChange={this.handleFreeFormPaginationChanges} size={6} />
                                    <button type="button" className="btn btn-secondary btn-lg mr-3"
                                            onClick={this.handleFreeFormPagination}>Go
                                    </button>
                                </div>
                            </div>
                            { this.state.freeFormPaginationValueFailed && <div className="row justify-content-md-center"> <div className="col-md-auto">
                                    <Alert severity="error">Allowed value is between 1 and {this.sourceVideoModel.length}</Alert>
                                </div></div>
                            }
                        </div>
                        <div className="col-2 border-secondary rounded pr-1" style={{minHeight: "600px"}}>
                            <ComparatorMatchingAction
                                sourceVideoModel={this.sourceVideoModel}
                                targetVideoModel={this.targetVideoModel}
                                metadataList={this.props.metadataList}
                                sourceVideoIndex={this.state.sourceVideoIndex}
                                targetVideoIndex={this.state.targetVideoIndex}
                                sourceFilePath = {this.props.sourceFilePath}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default VideoComparator;