/* istanbul ignore file */

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tagsinput/react-tagsinput.css';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Spinner } from '@amzn/awsui-components-react/polaris';
import ReactDOM from 'react-dom';
import Amplify, { Auth, Hub, Cache } from 'aws-amplify';
import App from './layout/App';
import { UnauthorizedPage } from './pages/ErrorPages/UnauthorizedPage';
import UserContext from './context/UserContext';
import initConfig from './config/init-config';

Amplify.configure(initConfig());

const AppWrapper: FunctionComponent = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, updateUser] = useState('Unidentified');

    useEffect(() => {
        (async () => {
            try {
                const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
                const username = currentAuthenticatedUser.getUsername().split('_')![1];
                updateUser(username);
                setIsAuthenticated(true);
                console.log('User authenticated: ', username);
                Hub.dispatch('auth', { event: 'signedIn' });
            } catch {
                Cache.setItem('location', window.location.toString());
                await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
            }
        })();
    });

    let content;
    if (user === 'Unidentified') {
        content = <Spinner />;
    } else if (isAuthenticated) {
        content = <App user={user}/>;
    } else {
        content = <UnauthorizedPage />;
    }
    return (
        <UserContext.Provider value={{ username: user}}>
             {content}
        </UserContext.Provider>
    );
};

ReactDOM.render(
    <AppWrapper>
    </AppWrapper>,
    document.getElementById('root')
);
