import React, { Fragment, Component } from 'react';
import {S3BucketFilePath} from "../interfaces/CommonTypes";
import VideoComparator from "./VideoComparator";
import XLSX from 'xlsx';
import {Alert, AlertTitle} from "@material-ui/lab";
import ApiHandler from '../api/api-handler';
import * as UrlUtils from "../utils/url-utils";
import {TextField, Divider, CircularProgress} from "@material-ui/core";

export interface HomePageProps {
    page?: string
}

export interface HomePageState {
    loadingVideoList: boolean,
    videoListAvailable: boolean,
    showAlert: boolean,
    sourceFilePath: string,
    showNotes: boolean,
    showError: boolean,
    errorMessage: string,
}

export class HomePage extends Component<HomePageProps, HomePageState> {
    private serviceAPI: ApiHandler;
    private sourceFilePath: string;
    private sourceVideoList: string[];
    private targetVideoList: string[];
    private metadataList : string[];

    public constructor(props: HomePageProps) {
        super(props);
        this.serviceAPI = new ApiHandler();
        this.state = {
            loadingVideoList: false,
            videoListAvailable: false,
            showAlert: true,
            sourceFilePath: '',
            showNotes: true,
            showError: false,
            errorMessage: '',
        };
        this.sourceFilePath = "";
        this.sourceVideoList = [];
        this.targetVideoList = [];
        this.metadataList = [];
    }

    handleSourceFileSubmit = (e:any) => {
        this.setState({loadingVideoList: true, videoListAvailable: false});
        this.readExcelFile(this.state.sourceFilePath);
    }

    readExcelFile = (fileurl: string) => {
        var s3BucketFilePath: S3BucketFilePath;
        try {
            s3BucketFilePath = UrlUtils.parseS3BucketAndFilePathFromS3Url(fileurl);
        } catch (error) {
            console.error(error);
            this.setState({loadingVideoList: false, showError: true, errorMessage: "Please provide a valid url."});
            return;
        }

        const promise = new Promise((resolve, reject) => {
            this.serviceAPI.getPresignedUrl(s3BucketFilePath.bucketname, s3BucketFilePath.filepath).then((presignedurl: string) => {
                this.serviceAPI.getRemoteSourceFile(presignedurl).then((response: ArrayBuffer) => {
                    const buffer = Buffer.from(response);
                    const workbook = XLSX.read(buffer, {type: "buffer"});
                    const worksheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[worksheetName];
                    const data = XLSX.utils.sheet_to_json(worksheet);
                    resolve(data);
                }).catch( (error: any) => {
                    console.error("Failed to fetch remote file " + fileurl);
                    console.error(error);
                    this.setState({loadingVideoList: false, showError: true, errorMessage: "Failed to fetch the requested filepath, make sure the requested bucket has CORS enabled for https://https://media-comparator.ads.amazon.dev domain."});
                });
            }).catch( (error: any) => {
                console.error("Failed to generate a presigned url for the requested filepath " + fileurl);
                console.error(error);
                this.setState({loadingVideoList: false, showError: true, errorMessage: "Failed to generate a presigned url for the requested filepath."});
            });
        });
        promise.then((data) => {
            this.processSourceData(data);
        }).catch( (error: any) => {
            console.error("Failed to parse the requested file " + fileurl);
            console.error(error);
            this.setState({loadingVideoList: false, showError: true, errorMessage: "Failed to parse the requested file. \n Make sure the excel sheet should have at least 2 columns with header name source_media_url and target_media_url"});
        });
        this.sourceFilePath = fileurl;
    }

    processSourceData = (data:any) => {
        for(var index:number = 0; index < data.length ; index++) {
            this.sourceVideoList.push(data[index]["source_media_url"]);
            this.targetVideoList.push(data[index]["target_media_url"]);
            this.metadataList.push( data[index]["match_rate"]);
        }
        this.setState({loadingVideoList: false, videoListAvailable: true, showNotes: false});
    }

    handleSourceFilePathChange = (event: any) => {
        this.setState({sourceFilePath: event.target.value, showNotes: true, showError: false});
    }

    closeAlert = () => {
        this.setState({showAlert: false});
    }

    render() {
        return (
            <div className='awsui'>
                <Fragment>
                    <div id="compare-video-display" className="container d-grid gap-3 pb-5" style={{maxWidth: "1800px"}}>
                        <h1 className="text-primary">Compare Video Side by Side</h1>
                        <div className="row align-items-start justify-content-start">
                            <div className="col-8">
                                <label htmlFor="source-file-input">Please enter the s3 bucket path of the source excel file</label>
                                <TextField
                                    id="source-file-input"
                                    onChange={this.handleSourceFilePathChange}
                                    variant="outlined"
                                    placeholder="https://admapping-final-output.s3.amazonaws.com/media_comparator_testing.csv"
                                    size="small"
                                    fullWidth
                                    error={this.state.showError}
                                    required
                                    style={{fontSize: "14px"}}
                                />
                                <button type="button" className="btn btn-primary btn-lg mt-3"
                                        onClick={this.handleSourceFileSubmit}>Submit
                                </button>
                            </div>
                        </div>
                        <div className="row justify-content-md-center">
                            <div style={{height: "20px", display: "block"}}></div>
                        </div>
                        {this.state.showError && <div className="row align-items-start justify-content-start">
                            <div className="col-8">
                                <Alert severity="error">
                                    <AlertTitle><p><strong>Error</strong></p></AlertTitle>
                                    <p> {this.state.errorMessage} </p>
                                </Alert>
                            </div>
                        </div>
                        }
                        {!this.state.showError && this.state.showNotes && <div className="row align-items-start justify-content-start">
                            <div className="col-8">
                                <Alert severity="info">
                                    <AlertTitle><p><strong>Note</strong></p></AlertTitle>
                                    <p>
                                        The excel sheet should have at least 2 columns with header name source_media_url and target_media_url.
                                    </p>
                                </Alert>
                            </div>
                        </div>
                        }
                        { this.state.loadingVideoList &&
                        <div className="row justify-content-md-center mt-5">
                            <CircularProgress color="inherit" />
                        </div>
                        }
                        {this.state.videoListAvailable &&
                        <div>
                            <Divider />
                            <div className="row justify-content-md-center">
                                <div style={{height: "20px", display: "block"}}></div>
                            </div>
                            <VideoComparator sourceVideoList={this.sourceVideoList} targetVideoList={this.targetVideoList} metadataList={this.metadataList} sourceFilePath={this.sourceFilePath}/>
                        </div>
                        }
                    </div>
                </Fragment>
            </div>
        );
    }
}

export default HomePage;