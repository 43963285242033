import React, {Fragment, Component} from 'react';
import {FormControl, RadioGroup, FormControlLabel, Radio, TextField, CircularProgress, Slider} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {VideoModel} from "../interfaces/CommonTypes";
import ApiHandler from '../api/api-handler';

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];

export interface ComparatorMatchingActionProps {
    sourceVideoModel: VideoModel[],
    targetVideoModel: VideoModel[],
    metadataList: string[],
    sourceVideoIndex: number,
    targetVideoIndex: number,
    sourceFilePath: string,
}

export interface ComparatorMatchingActionState {
    qaVideoMatch: boolean,
    qaComments: string,
    qaMatchRate: number | number[],
    businessVideoMatch: boolean,
    businessComments: string,
    businessMatchRate: number | number[],
    commentsSubmitting: boolean,
    loadingDetails: boolean,
    systemDefaultForQA: boolean,
    systemDefaultForBusiness: boolean,
    sourceIndex: number,
    targetIndex:number,
    commentSaveSuccess: boolean,
    commentSaveFailed: boolean,
}

export class ComparatorMatchingAction extends Component<ComparatorMatchingActionProps, ComparatorMatchingActionState> {
    private serviceAPI: ApiHandler;

    public constructor(props: ComparatorMatchingActionProps) {
        super(props);
        this.serviceAPI = new ApiHandler();
        this.state = {
            qaVideoMatch: false,
            qaComments: "",
            qaMatchRate: 0,
            businessVideoMatch: false,
            businessComments: "",
            businessMatchRate: 0,
            commentsSubmitting: false,
            loadingDetails: false,
            systemDefaultForQA: true,
            systemDefaultForBusiness: true,
            sourceIndex: 0,
            targetIndex:0,
            commentSaveSuccess: false,
            commentSaveFailed: false,
        };
    }

    componentDidMount = () => {
        this.getVideoMatchDetails(this.props.sourceVideoIndex, this.props.targetVideoIndex);
    }

    componentWillReceiveProps = (event:any) => {
        console.log("componentWillReceiveProps is called");
        console.log("event.sourceVideoIndex  " + event.sourceVideoIndex + "  this.state.sourceIndex  " + this.state.sourceIndex);
        console.log("event.targetVideoIndex  " + event.targetVideoIndex + "  this.state.targetIndex  " + this.state.targetIndex);
        console.log(event);
        if (this.state.sourceIndex !== event.sourceVideoIndex || this.state.targetIndex !== event.targetVideoIndex) {
            this.getVideoMatchDetails(event.sourceVideoIndex, event.targetVideoIndex);
        }
    }

    handleQAVideoMatchChange = (event:any) => {
        this.setState({systemDefaultForQA: false, qaVideoMatch: event.target.value === "Yes"});
    }

    handleBusinessVideoMatchChange = (event:any) => {
        this.setState({systemDefaultForBusiness: false, businessVideoMatch: event.target.value === "Yes"});
    }

    handleQAMatchRateChange = (event: any, newValue: number | number[]) => {
        console.log("QA match rate is " + newValue);
        this.setState({qaMatchRate: newValue});
    }

    handleQACommentsChange = (event:any) => {
        this.setState({qaComments: event.target.value});
    }

    handleBusinessCommentsChange = (event:any) => {
        this.setState({businessComments: event.target.value});
    }

    handleBusinessMatchRateChange = (event: any, newValue: number | number[]) => {
        console.log("Business match rate is " + newValue);
        this.setState({businessMatchRate: newValue});
    }

    getVideoMatchDetails = (sourceIndex:number, targetIndex:number) => {
        console.log("Fetching matching comments");
        this.resetState();
        this.setState({loadingDetails: true});
        let params = {
            filename: this.props.sourceFilePath,
            sourceVideoId: this.props.sourceVideoModel[sourceIndex].sourceurl,
            targetVideoId: this.props.targetVideoModel[targetIndex].sourceurl,
        }
        this.serviceAPI.getVideoMatchComments(params).then(( response: any) => {
            console.log("successfully get the matching action input");
            console.log(response);
            if (response.hasOwnProperty("QAComments")) {
                this.setState({
                    qaVideoMatch: response.QAVideoMatch,
                    qaComments: response.QAComments,
                    businessVideoMatch: response.BusinessVideoMatch,
                    businessComments: response.BusinessComments,
                    systemDefaultForQA: false,
                    systemDefaultForBusiness: false,
                    qaMatchRate: typeof response.QAMatchRate === 'number' ? response.QAMatchRate : 0,
                    businessMatchRate: typeof response.BusinessMatchRate === 'number' ? response.BusinessMatchRate : 0,
                });
            }
        }).catch( (error: any) => {
            console.error("Failed to get matching comments " + JSON.stringify(params));
            console.error(error);
        }).finally( () => {
            this.setState({
                loadingDetails: false,
                sourceIndex: sourceIndex,
                targetIndex: targetIndex,
            });
        });
    }

    handleMatchingActionSubmit = (e:any) => {
        console.log("handleMatchingActionSubmit is clicked");
        this.setState({commentsSubmitting: true});
        let params = {
            filename: this.props.sourceFilePath,
            sourceVideoId: this.props.sourceVideoModel[this.state.sourceIndex].sourceurl,
            targetVideoId: this.props.targetVideoModel[this.state.targetIndex].sourceurl,
            qaVideoMatch: this.state.qaVideoMatch,
            qaComments: this.state.qaComments,
            businessVideoMatch: this.state.businessVideoMatch,
            businessComments: this.state.businessComments,
            qaMatchRate: this.state.qaMatchRate,
            businessMatchRate: this.state.businessMatchRate,
        }
        console.log("PARAMS are")
        console.log(params)

        this.serviceAPI.saveVideoMatchComments(params).then(( response: any) => {
            console.log("successfully saved the matching action input");
            console.log(response);
            this.setState({commentSaveSuccess: true});
        }).catch( (error: any) => {
            console.error("Failed to submit matching comments " + JSON.stringify(params));
            console.error(error);
            this.setState({commentSaveFailed: true});
        }).finally( () => {
            this.setState({commentsSubmitting: false});
        });
    }

    resetState = () => {
        console.log("Resetting matching action input")
        this.setState({
            qaVideoMatch: false,
            qaComments: "",
            businessVideoMatch: false,
            businessComments: "",
            systemDefaultForQA: true,
            systemDefaultForBusiness: true,
            commentSaveSuccess: false,
            commentSaveFailed: false,
            qaMatchRate: 0,
            businessMatchRate: 0,
        });
    }

    render() {
        return (
            <Fragment>
                {!this.state.loadingDetails &&
                <div id="compare-matching-action" className="row border-secondary border-top border-left mb-10" >
                    <div className="col-12">
                        <h4>QA Analysis</h4>
                        <label>Do the source and target video match ?</label>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="Do these videos match?" 
                                name="VideoMatch"
                                onChange={this.handleQAVideoMatchChange}
                                value={this.state.systemDefaultForQA ? "None" : (this.state.qaVideoMatch ? "Yes": "No")}
                            >
                                <FormControlLabel value="Yes" control={<Radio size="medium" />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio size="medium" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="col-12">
                        <label htmlFor="qa-commets-text-field"><h4>QA comments</h4></label>
                        <TextField
                            id="qa-commets-text-field"
                            label="QA comments"
                            multiline
                            onChange={this.handleQACommentsChange}
                            variant="outlined"
                            placeholder="QA comments"
                            rows={5}
                            size="small"
                            fullWidth
                            value = {this.state.qaComments}
                        />
                    </div>
                    <div className="col-12">
                        <label htmlFor="qa-match-rate"><h4>QA Matched %</h4></label>
                        <Slider
                            id="qa-match-rate"
                            defaultValue={this.state.qaMatchRate}
                            step={1}
                            valueLabelDisplay="auto"
                            onChange={this.handleQAMatchRateChange}
                            marks={marks}
                        />
                    </div>
                    <div className="col-12 border-secondary border-top mt-3">
                        <h4>Business Analysis</h4>
                        <label>Do the source and target video match ?</label>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="Do these videos match?"
                                name="VideoMatch"
                                onChange={this.handleBusinessVideoMatchChange}
                                value={this.state.systemDefaultForBusiness ? "None" : (this.state.businessVideoMatch ? "Yes": "No")}>
                                <FormControlLabel value="Yes" control={<Radio size="medium" />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio size="medium" />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="col-12">
                        <label htmlFor="dev-commets-text-field"><h4>Business comments</h4></label>
                        <TextField
                            id="business-commets-text-field"
                            label="Business comments"
                            multiline
                            onChange={this.handleBusinessCommentsChange}
                            variant="outlined"
                            placeholder="Business comments"
                            rows={5}
                            size="medium"
                            fullWidth
                            value = {this.state.businessComments}
                        />
                    </div>
                    <div className="col-12">
                        <label htmlFor="business-match-rate"><h4>Business Matched %</h4></label>
                        <Slider
                            id="business-match-rate"
                            defaultValue={this.state.businessMatchRate}
                            step={1}
                            valueLabelDisplay="auto"
                            onChange={this.handleBusinessMatchRateChange}
                            marks={marks}
                        />
                    </div>
                    <div className="col-12 border-secondary border-top mt-3">
                        <button type="button" className="btn btn-primary btn-lg mt-3"
                                onClick={this.handleMatchingActionSubmit}
                                disabled={this.state.commentsSubmitting}>Submit
                        </button>
                    </div>
                    { this.state.commentsSubmitting && <div className="col-12 mt-3">
                        <CircularProgress color="inherit" size="2rem"/>
                    </div>
                    }
                </div>
                }
                { this.state.loadingDetails &&
                <div className="row justify-content-md-center mt-3">
                    <CircularProgress color="inherit" size="2rem"/>
                </div>
                }
                { !this.state.commentsSubmitting && this.state.commentSaveSuccess &&
                <div className="row justify-content-md-center mt-3">
                    <Alert severity="success">Successfully saved!</Alert>
                </div>
                }
                { !this.state.commentsSubmitting && this.state.commentSaveFailed &&
                <div className="row justify-content-md-center mt-3">
                    <Alert severity="error">Failed to save!</Alert>
                </div>
                }
            </Fragment>
        );
    }
}

export default ComparatorMatchingAction;