/* istanbul ignore file */

import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import { Auth } from 'aws-amplify';
import { Endpoint } from '../interfaces/CommonTypes';

export default class ApiHandler {
    private instance: AxiosInstance;
    private config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-amz-json-1.1'
        }
    };

    public constructor() {
        const baseURL = Endpoint.FETCH_PRE_SIGNED_URL_ENDPOINT;
        this.instance = axios.create({baseURL});
    }

    public getInstance = () => this.instance;

    public getConfig = () => this.config

    private authenticate = async() => {
        const session = await Auth.currentSession();
        this.config.headers['Authorization'] = session.getIdToken().getJwtToken();
    }

    public getPresignedUrl = async(bucketname: string, filepath: string): Promise<string> => {
        this.config = {
            headers: {
                'Content-Type': 'application/x-amz-json-1.1'
            }
        };
        await this.authenticate();
        const response = await this.instance.get(Endpoint.FETCH_PRE_SIGNED_URL_ENDPOINT, {params: {bucketname, filepath}, headers: this.config.headers});
        if (!response) {
            console.error('No response from API Gateway service');
        }
        if (response.status === 200) {
            console.log(response);
        } else {
            console.error('Invalid Response: ', response);
        }
        return response.data.presignedurl;
    }

    public getRemoteSourceFile = async(url: string): Promise<ArrayBuffer> => {
        await this.authenticate();
        const requestConfig: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/x-amz-json-1.1'
            },
            responseType: 'arraybuffer'
        };
        const response = await this.instance.get(url, requestConfig);
        return response.data;
    }

    public getVideoMatchComments = async(params: any): Promise<string> => {
        this.config = {
            headers: {
                'Content-Type': 'application/x-amz-json-1.1'
            }
        };
        await this.authenticate();
        const response = await this.instance.get(Endpoint.GET_VIDEO_MATCH_COMMENTS, {params: params, headers: this.config.headers});
        if (!response) {
            console.error('No response from API Gateway service while fetching matching action response');
        }
        if (response.status === 200) {
            console.log("Successfully fetch matching result");
            console.log(response);
        } else {
            console.error('Invalid Response while fetching matching result: ', response);
        }
        if (response.data && response.data.Item) {
            return response.data.Item;
        } else {
            return response.data;
        }
        
    }

    public saveVideoMatchComments = async(params: any): Promise<string> => {
        this.config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
        await this.authenticate();
        const response = await this.instance.post(
            Endpoint.SAVE_VIDEO_MATCH_COMMENTS,
            params,
            this.config
            );
        if (!response) {
            console.error('No response from API Gateway service while fetching matching action response');
        }
        if (response.status === 200) {
            console.log("Successfully fetch matching result");
            console.log(response);
        } else {
            console.error('Invalid Response while fetching matching result: ', response);
        }
        return response.data;
    }
}