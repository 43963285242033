import {S3BucketFilePath, VideoModel} from "../interfaces/CommonTypes";
import * as UrlUtils from "./url-utils";

export const parseVideoList = (videoList: string[]): VideoModel[] => {
    var videoModel: VideoModel;
    var s3BucketFilePath: S3BucketFilePath
    var videoModelArray: VideoModel[] = [];
    videoList.forEach((item, index) => {
        s3BucketFilePath = UrlUtils.parseS3BucketAndFilePathFromS3Url(item);
        videoModel = {
            sourceurl: item, 
            bucketname: s3BucketFilePath.bucketname,
            filepath: s3BucketFilePath.filepath,
            isS3url: s3BucketFilePath.bucketname !== ""
        };
        videoModelArray[index] = videoModel;
    });
    return videoModelArray;
}