export enum Endpoint {
    FETCH_PRE_SIGNED_URL_ENDPOINT = "https://nyohafoc4m.execute-api.us-east-1.amazonaws.com/prod/fetch-file-by-path",
    GET_VIDEO_MATCH_COMMENTS = "https://nyohafoc4m.execute-api.us-east-1.amazonaws.com/prod/video-match-comments/get",
    SAVE_VIDEO_MATCH_COMMENTS = "https://nyohafoc4m.execute-api.us-east-1.amazonaws.com/prod/video-match-comments/update",
}

export interface FetchPresignedUrlInput {
    bucketname: string;
    filepath: string
}

export interface FetchPresignedUrlOutput {
    presignedurl: string
}

export interface S3BucketFilePath extends FetchPresignedUrlInput {}

export interface VideoModel extends S3BucketFilePath {
    sourceurl: string,
    presignedurl?: string,
    isS3url?: boolean,
}

export const AcceptedFormat = [
    "xlsx", "xlsb", "xlsm", "xls"
].map(function(format) { return "." + format; }).join(",");