import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import { PageSection } from '../../layout/PageSection';
import { MessageBox, MessageType } from '../../common/MessageBox';

export const UnauthorizedPage: FunctionComponent = () => (
    <PageSection testId={'unauthorized'}>
        <MessageBox testId={'unauthorizedpage'} type={MessageType.ERROR}>
            <Typography variant='h5'>Hello!</Typography>
            <Typography variant='h6'>You are not Authorized to view this page</Typography>
            <Typography variant='body2'>Error 403</Typography>
        </MessageBox>
    </PageSection>
);