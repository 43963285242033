import { Stage } from '../api/api-constants';
import {S3BucketFilePath} from '../interfaces/CommonTypes';

export const parseStageFromUrl
    = (url: URL): Stage => {
    const hostname = url.hostname;

    if (hostname === BETA_LIMESTONE_PORTAL_URL) {
        return Stage.BETA;
    } else {
        return Stage.DEV
    }
};

export const parseS3BucketAndFilePathFromS3Url = (path: string): S3BucketFilePath => {
    let bucketname: string = "";
    let filepath: string = "";
    if (path.includes(S3_URL_PREFIX)) {
        const url:URL = new URL(path);
        bucketname = url.hostname.substr(0, url.hostname.indexOf(S3_URL_PREFIX) -1);
        filepath = url.pathname.substr(1, url.pathname.length);
    }
    const s3BucketFilePath: S3BucketFilePath  = {
        bucketname: bucketname,
        filepath: filepath
    };
    return s3BucketFilePath;
};

export const BETA_LIMESTONE_PORTAL_URL = 'media-comparator.ads.amazon.dev';
export const S3_URL_PREFIX ="s3.amazonaws.com";