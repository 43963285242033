import React, {FunctionComponent} from 'react';
import {Hub, Cache} from 'aws-amplify';
import Homepage from '../pages/homepage';
interface AppProps {
    user: string
}

const App: FunctionComponent<AppProps> = ({ user }) => {

    Hub.listen('auth', (data) => {
        if (data.payload.event === 'signedIn' && Cache.getItem('location') !== null) {
        }
    });
    return (
        <Homepage />
    );
};

export default App;